import React, { useEffect,useState } from 'react'
import BreadCrum from '../components/common/BreadCrum'
import { useNavigate } from 'react-router-dom'
    
const News = () => {

     const navigate = useNavigate()
    const [newsList,setNewsList] =useState()
    const [viewAll,setViewAll] = useState(false)
 
    const [gallaryviewAll,setGallaryViewAll] = useState(false)

    const imageCount = 33; // Set the number of images you want to display
    const images = Array.from({ length: imageCount }, (_, index) => `gallary-${index + 1}`);
  
    

    useEffect(()=>{

        const fetchNews = async () =>{
                
            try{
                const response = await fetch(`${process.env.REACT_APP_BASEURL}/api/news?populate=*`);
                const data = await response.json();

                setNewsList(data.data)
            } catch (error){
                console.log("Error;", error);
            }

        }
        fetchNews()

    },[])


  const handleReadMore = (newsId) => {
    navigate("/news-detail", { state: { id: `${newsId}` } });
  };



  
  return (<>
    <BreadCrum title={"News & Media"} slogan={"Sustainable Solutions, Lasting Impact: Datta Infra's Journey Towards a Greener Future"} />

    {/* <!--==============================
    Blog Area
    ==============================--> */}
    <section className=" vs-blog-wrapper space-extra-bottom">
        <div className="space-top container">
            <div className="title-area text-center wow fadeInUp" data-wow-delay="0.3s">
                {/* <svg className="sec-icon" width="68" height="67" viewBox="0 0 68 67">
                    <path className="sec-shape" d="M926,1225l63-39-21-1,26-27-58,35,21,1" transform="translate(-926 -1158)"></path>
                </svg> */}
                <span className="sec-subtitle">latest updates</span>
                <h2 className="sec-title h1">Recent News.</h2>
            </div>
            <div className="row  wow fadeInUp" data-wow-delay="0.4s" data-slide-show="3" data-lg-slide-show="2" data-md-slide-show="2">

{    newsList?.slice(0,3).map((news,i)=>( 
              
              <div key={i} className="col-xl-4">
                    <div className="vs-blog blog-style1">
                        <div className="m-2">
                            <img
                             className="border" 
                             style={{borderRadius:"35px" , width:"100%", objectFit:"cover",height:"300px"}} 
                             src={`${process.env.REACT_APP_BASEURL}${news.attributes.image.data.attributes.url}`}
                             />
                        </div>
                    </div>
                    <div className="vs-blog blog-style1">
                     <h3 className="blog-title"><a  className='pointer'  onClick={() => handleReadMore(news.id)} >{news.attributes.title}</a></h3>
                 </div>
                </div>
              
            ))}



{ viewAll &&   newsList?.slice(3,newsList.length).map((news)=>( 
              <>
              
              <div className="col-xl-4">
                    <div className="vs-blog blog-style1">
                        <div className="m-2">
                            <img
                             className="border" 
                             style={{borderRadius:"35px" , width:"100%", objectFit:"cover",height:"300px"}} 
                             src={`${process.env.REACT_APP_BASEURL}${news.attributes.image.data.attributes.url}`}
                             />
                        </div>
                    </div>
                    <div className="vs-blog blog-style1">
                     <h3 className="blog-title pointer"><a                onClick={() => handleReadMore(news.id)}>{news.attributes.title}</a></h3>
                 </div>
                </div>
              
              </>
            ))}
{/* 

            <div className="col-xl-4">
                    <div className="vs-blog blog-style1">
                        <div className="">
                            <img className="border" style={{borderRadius:"35px"}}  src="assets/img/about/ab-3-1.jpg" alt="Blog Image" className="w-100"/>
                        </div>
                    </div>
                    <div className="vs-blog blog-style1">
                     <h3 className="blog-title"><a href="blog-details.html">Surviving Sustainably On Solar Energy 2022</a></h3>
                 </div>
                </div>
                <div className="col-xl-4">
                    <div className="vs-blog blog-style1">
                        <div className="">
                            <img className="border" style={{borderRadius:"35px"}}  src="assets/img/about/ab-3-1.jpg" alt="Blog Image" className="w-100"/>
                        </div>
                    </div>
                    <div className="vs-blog blog-style1">
                     <h3 className="blog-title"><a href="blog-details.html">Surviving Sustainably On Solar Energy 2022</a></h3>
                 </div>
                </div>
                <div className="col-xl-4">
                    <div className="vs-blog blog-style1">
                        <div className="">
                            <img className="border" style={{borderRadius:"35px"}}  src="assets/img/about/ab-3-1.jpg" alt="Blog Image" className="w-100"/>
                        </div>
                    </div>
                    <div className="vs-blog blog-style1">
                     <h3 className="blog-title"><a href="blog-details.html">Surviving Sustainably On Solar Energy 2022</a></h3>
                 </div>
                </div> */}

            </div>
            <div className="bottom-btn ">
                <a  onClick={()=>{setViewAll(!viewAll)}} className="vs-btn pointer">{viewAll ?"View only Recents" :"View All News"}</a>
            </div>
        </div>
    </section>

    <section className=" vs-blog-wrapper space-extra-bottom">
        <div className="space-top container">
            <div className="title-area text-center wow fadeInUp" data-wow-delay="0.3s">
                {/* <svg className="sec-icon" width="68" height="67" viewBox="0 0 68 67">
                    <path className="sec-shape" d="M926,1225l63-39-21-1,26-27-58,35,21,1" transform="translate(-926 -1158)"></path>
                </svg> */}
                <h2 className="sec-title h1">Gallery</h2>
            </div>
            <div className="row  wow fadeInUp" data-wow-delay="0.4s" data-slide-show="3" data-lg-slide-show="2" data-md-slide-show="2">

              
            <div className="row">
            {images?.slice(0, 3).map((mediaName, index) => (
                <div key={index} className="col-xl-4">
                  <div className="vs-blog blog-style1">
                    <div className="m-2">
                      {/* Check if the index is one of the specific values for videos */}
                      { [28, 29,30, 31, 32,33].includes(index) ? (
                        <video
                          className="border"
                          style={{
                            borderRadius: "35px",
                            width: "100%",
                            objectFit: "cover",
                            height: "300px",
                          }}
                          controls
                          src={`/assets/img/gallary/${mediaName}.mp4`}
                        />
                      ) : (
                        <img
                          className="border"
                          style={{
                            borderRadius: "35px",
                            width: "100%",
                            objectFit: "cover",
                            height: "300px",
                          }}
                          src={`/assets/img/gallary/${mediaName}.jpeg`}
                          alt={`Gallery media ${index + 1}`}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
              
              {gallaryviewAll &&
                images?.slice(3).map((mediaName, index) => (
                  <div key={index + 3} className="col-xl-4">
                    <div className="vs-blog blog-style1">
                      <div className="m-2">
                        {/* Adjust for the specific index values */}
                        { [27, 28, 29,30, 31, 32,33].includes(index + 3) ? (
                          <video
                            className="border"
                            style={{
                              borderRadius: "35px",
                              width: "100%",
                              objectFit: "cover",
                              height: "300px",
                            }}
                            controls
                            src={`/assets/img/gallary/${mediaName}.mp4`}
                          />
                        ) : (
                          <img
                            className="border"
                            style={{
                              borderRadius: "35px",
                              width: "100%",
                              objectFit: "cover",
                              height: "300px",
                            }}
                            src={`/assets/img/gallary/${mediaName}.jpeg`}
                            alt={`Gallery media ${index + 4}`}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              
          </div>
              




{/* 

            <div className="col-xl-4">
                    <div className="vs-blog blog-style1">
                        <div className="">
                            <img className="border" style={{borderRadius:"35px"}}  src="assets/img/about/ab-3-1.jpg" alt="Blog Image" className="w-100"/>
                        </div>
                    </div>
                    <div className="vs-blog blog-style1">
                     <h3 className="blog-title"><a href="blog-details.html">Surviving Sustainably On Solar Energy 2022</a></h3>
                 </div>
                </div>
                <div className="col-xl-4">
                    <div className="vs-blog blog-style1">
                        <div className="">
                            <img className="border" style={{borderRadius:"35px"}}  src="assets/img/about/ab-3-1.jpg" alt="Blog Image" className="w-100"/>
                        </div>
                    </div>
                    <div className="vs-blog blog-style1">
                     <h3 className="blog-title"><a href="blog-details.html">Surviving Sustainably On Solar Energy 2022</a></h3>
                 </div>
                </div>
                <div className="col-xl-4">
                    <div className="vs-blog blog-style1">
                        <div className="">
                            <img className="border" style={{borderRadius:"35px"}}  src="assets/img/about/ab-3-1.jpg" alt="Blog Image" className="w-100"/>
                        </div>
                    </div>
                    <div className="vs-blog blog-style1">
                     <h3 className="blog-title"><a href="blog-details.html">Surviving Sustainably On Solar Energy 2022</a></h3>
                 </div>
                </div> */}

            </div>
            <div className="bottom-btn ">
                <a  onClick={()=>{setGallaryViewAll(!gallaryviewAll)}} className="vs-btn pointer">{gallaryviewAll ?"View only Recents Gallery" :"View All Gallery"}</a>
            </div>
        </div>
    </section>

{/* 
    <section className=" space-bottom  mt-3">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-12 col-xl-6 text-center wow fadeInUp" data-wow-delay="0.3s">

    <div className="title-area ">
                        <h2 className="sec-title h1 mb-2">Our Media kit</h2>
                        <p className="sec-text">Find all the visual assets, including logos, product visuals and founders and spokesperson photos</p>
                    </div>  
                    </div>
            </div>
            <div className='row col-md-12 m-auto' >


            <div className="col-xl-4 col-md-12 col-sm-12 mt-2 ">
                    <div className="vs-blog blog-style1 bg-dark mediakit m-auto " style={{ borderRadius:"35px", height:"360px" , width:"350px", padding:"10px"}} >
                        <div className="blog-im ">
                            <img className="border w-100" style={{borderRadius:"35px",height:"300px"}}  height="350px" width="450px"  src="assets/img/about/CorporateBrochure.png" alt="Blog Image"
                            //  className="w-100"
                             />
                        </div>
                        <h5 className='text-white text-center mt-2'>Corporate Brochure</h5>
                    </div>
                </div>


                <div className="col-xl-4 col-md-12 col-sm-12 mt-2 ">
                    <div className="vs-blog blog-style1 bg-dark mediakit m-auto" style={{ borderRadius:"35px", height:"360px" , width:"350px", padding:"10px"}} >
                        <div className="blog-im">
                            <img className="border w-100" style={{borderRadius:"35px",height:"300px"}}  height="500px" width="500px"  src="assets/img/logo.png" alt="Blog Image" 
                            // className="w-100"
                            />
                        </div>
                        <h5 className='text-white text-center mt-2'>Corporate Logo</h5>
                    </div>
                 
                </div>


                <div className="col-xl-4 col-md-12 col-sm-12 mt-2 ">
                    <div className="vs-blog blog-style1 bg-dark mediakit m-auto"  style={{ borderRadius:"35px", height:"360px" , width:"350px", padding:"10px"}} >
                        <div className="blog-im">
                            <img className="border w-100" style={{borderRadius:"35px",height:"300px"}} height="500px" width="500px"  src="assets/img/about/award.jpeg" alt="Blog Image" 
                            // className="w-100"
                            />
                        </div>
                        <h5 className='text-white text-center mt-2'>Corporate Profile</h5>
                    </div>
                 
                </div>


            </div>



        </div>

        
    </section> */}







  </>
  )
}

export default News