import React from "react";

const GoogleMap = () => {
  return (
    <>
      <div className="container space-bottom">
        <div className="ratio ratio-21x9">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.5794028424657!2d77.07999345083924!3d28.40176891356248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d223e7bd826cf%3A0xbdbbf829f05e9a5d!2sAipl%20Business%20Club%2C%20Sector%2062%2C%20Gurugram%2C%20Haryana%20122102!5e0!3m2!1sen!2sin!4v1731989886185!5m2!1sen!2sin"
            width="800"
            height="400"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default GoogleMap;